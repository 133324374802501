<template>
  <div>
    <eden-page-stats
      class="mt-0"
      :title="'Overview'"
      :loading="loading"
      :stats="stats"
      :show-periods="true"
      :periods="periods"
      :show-custom-period="true"
      @set-period="getScheduleStats"
    />
    <eden-table-actions :title="title" @search="setSearchQuery">
      <template slot="actions">
        <eden-periods
          :default-period="'today'"
          :show-custom-period="true"
          @set-period="getSchedule"
        />
      </template>
    </eden-table-actions>
    <all-orders-view
      :loading="loading"
      :service="'beauty'"
      :orders="schedule"
      :show-personnel-column="false"
    />
  </div>
</template>

<script>
import { beautySchedule } from "./Views/mock";
import AllOrdersView from "@/components/Logistics/Schedule/Views/AllOrdersView";

export default {
  name: "ScheduleBeauty",
  components: { AllOrdersView },
  data() {
    return {
      loading: false,
      searchQuery: "",
      period: "today",
      periods: {
        today: "Today",
        tomorrow: "Tomorrow",
        thisweek: "This week",
      },
      schedule: beautySchedule(),
    };
  },
  computed: {
    title() {
      return `${this.schedule.length}  Orders`;
    },
    stats() {
      return [
        {
          label: "Beauty orders",
          figure: 21,
        },
        {
          label: "Completed orders",
          figure: 15,
        },
        {
          label: "Incomplete orders",
          figure: 6,
        },
      ];
    },
  },
  methods: {
    setSearchQuery(query) {
      this.searchQuery = query;
    },
    getScheduleStats({ period, from, to }) {
      this.period = period;
      console.log(period, from, to);
    },
    getSchedule({ period, from, to }) {
      console.log(period, from, to);
    },
  },
};
</script>
