<template>
  <div>
    <eden-table-actions :title="title" :show-search="false">
      <template slot="title">
        <slot name="title" />
      </template>
      <template slot="actions">
        <eden-periods
          class="mr-10"
          :default-period="'today'"
          :show-custom-period="true"
          @set-period="$emit('set-period', $event)"
        />
        <el-dropdown @command="setView">
          <el-button type="plain" :disabled="loading">
            {{ views[view] }} <i class="eden-icon-arrow-down"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              :class="{ selected: view === 'all-orders' }"
              :command="'all-orders'"
              >All Orders View</el-dropdown-item
            >
            <el-dropdown-item
              :class="{ selected: view === 'riders' }"
              :command="'riders'"
              >Riders View</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <component
      v-else
      :is="`${view}-view`"
      :service="service"
      :schedule="schedule"
      :orders="orders"
      :period="period"
    />
  </div>
</template>

<script>
export default {
  name: "ScheduleView",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    service: {
      type: String,
      default: "",
    },
    period: {
      type: String,
      default: "",
    },
    schedule: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    AllOrdersView: () => import("./AllOrdersView"),
    RidersView: () => import("./RidersView"),
  },
  data() {
    return {
      view: "riders",
      views: {
        "all-orders": "All Orders View",
        riders: "Riders View",
      },
    };
  },
  computed: {
    title() {
      return `${this.schedule.length} Orders`;
    },
    orders() {
      let orders = [];
      const personnel = this.service === "cleaning" ? "cleaners" : "rider";
      orders = this.schedule.reduce(
        (personnels, schedule) => [
          ...personnels,
          ...schedule.orders.map((order) => {
            return {
              ...order,
              [personnel]: schedule[personnel].name,
            };
          }),
        ],
        []
      );
      return orders;
    },
  },
  methods: {
    setView(view) {
      this.view = view;
    },
  },
};
</script>
