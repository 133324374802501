<template>
  <div>
    <eden-page-stats
      class="mt-0"
      :title="'Overview'"
      :loading="loading"
      :stats="stats"
      :show-periods="true"
      :periods="periods"
      :show-custom-period="true"
      @set-period="getScheduleStats"
    />
    <schedule-view
      :loading="loading"
      :service="'laundry-delivery'"
      :period="period"
      :schedule="schedule"
      @set-period="getSchedule"
    />
  </div>
</template>

<script>
import { laundryDeliverySchedule } from "./Views/mock";
import ScheduleView from "@/components/Logistics/Schedule/Views/ScheduleView";

export default {
  name: "ScheduleLaundryDelivery",
  components: { ScheduleView },
  data() {
    return {
      loading: false,
      period: "today",
      periods: {
        today: "Today",
        tomorrow: "Tomorrow",
        thisweek: "This week",
      },
      schedule: laundryDeliverySchedule(),
    };
  },
  computed: {
    title() {
      return `${this.schedule.length}  Deliveries Today`;
    },
    stats() {
      const stats = [
        {
          label: "Laundry deliveries",
          figure: 58,
        },
        {
          label: "Riders assigned",
          figure: 23,
        },
        {
          label: "Riders completed",
          figure: 12,
        },
        {
          label: "Riders pending",
          figure: 11,
        },
        {
          label: "Complete deliveries",
          figure: 32,
        },
        {
          label: "Incomplete deliveries",
          figure: 26,
        },
      ];

      return this.period !== "today" ? stats.slice(0, 2) : stats;
    },
  },
  methods: {
    getScheduleStats({ period, from, to }) {
      this.period = period;
      console.log(period, from, to);
    },
    getSchedule({ period, from, to }) {
      console.log(period, from, to);
    },
  },
};
</script>
