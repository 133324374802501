<template>
  <div>
    <el-table v-loading="loading" :data="orders">
      <el-table-column width="80">
        <template slot="header">
          <div class="table--header">
            <span>Order Id</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'orders.order',
              params: { id: scope.row.order_id },
            }"
          >
            <span class="font-sm text-primary">
              {{ scope.row.order_id }}</span
            ></router-link
          >
        </template>
      </el-table-column>
      <el-table-column :width="service !== 'cleaning' ? 200 : 300">
        <template slot="header">
          <div class="table--header">
            <span>Customer</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm"> {{ formatName(scope.row.customer) }}</span>
          <span class="font-xsm text-grey-tertiary">
            {{ scope.row.gardener }}</span
          >
        </template>
      </el-table-column>
      <el-table-column v-if="service !== 'meal'" width="120">
        <template slot="header">
          <div class="table--header">
            <span>Type</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">
            {{ formatToTitleCase(scope.row.type) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column width="120">
        <template slot="header">
          <div class="table--header">
            <span>{{ quantityColumn }}</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">
            {{ scope.row.quantity }}
          </span>
        </template>
      </el-table-column>
      <template v-if="showPersonnelColumn">
        <el-table-column v-if="service === 'cleaning'" width="220">
          <template slot="header">
            <div class="table--header">
              <span>Assigned Cleaners</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span
              v-for="(person, index) in scope.row.cleaners"
              :key="index"
              :style="{ display: 'block' }"
              >{{ person }}</span
            >
          </template>
        </el-table-column>
        <el-table-column v-else width="220">
          <template slot="header">
            <div class="table--header">
              <span>Rider</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              {{ scope.row.rider }}
            </span>
          </template>
        </el-table-column>
      </template>
      <el-table-column width="140">
        <template slot="header">
          <div class="table--header">
            <span>Date</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">
            {{ formatDate(scope.row.date, "m do, y") }}
          </span>
        </template>
      </el-table-column>
      <el-table-column width="140">
        <template slot="header">
          <div class="table--header">
            <span>Status</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <eden-tag :value="scope.row.status" />
        </template>
      </el-table-column>
      <el-table-column width="200">
        <template slot="header">
          <div class="table--header">
            <span>Location Area</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">
            {{ scope.row.locationarea }}
          </span>
        </template>
      </el-table-column>
      <el-table-column width="200">
        <template slot="header">
          <div class="table--header">
            <span>Address</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">
            {{ scope.row.customer_address }}
          </span>
        </template>
      </el-table-column>
      <template v-if="['beauty', 'cleaning'].includes(service)">
        <el-table-column width="200">
          <template slot="header">
            <div class="table--header">
              <span>Start time</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              {{ formatTime(scope.row.start_time) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <div class="table--header">
              <span>Completion time</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              {{ formatTime(scope.row.completion_time) }}
            </span>
          </template>
        </el-table-column>
      </template>
      <template v-else>
        <el-table-column width="200">
          <template slot="header">
            <div class="table--header">
              <span>{{ label }} time</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              {{ formatTime(scope.row.time) }}
            </span>
          </template>
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "AllOrdersView",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    service: {
      type: String,
      default: "",
    },
    showPersonnelColumn: {
      type: Boolean,
      default: true,
    },
    orders: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    label() {
      return this.service.includes("pickup") ? "Pickup" : "Delivery";
    },
    quantityColumn() {
      switch (this.service) {
        case "beauty":
          return "No of Variations";
        case "cleaning":
          return "No of Rooms";
        case "laundry-pickup":
          return "No of Bags";
        case "laundry-delivery":
          return "No of Bags";
        case "meal":
          return "No of Meals";
        default:
          return "";
      }
    },
  },
};
</script>
