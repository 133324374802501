<template>
  <div class="home">
    <eden-page-header :title="'Operations Schedule'">
      <template slot="actions">
        <el-dropdown @command="schedule">
          <el-button type="primary">
            Create Schedule <i class="eden-icon-arrow-down"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(schedule, i) in schedules"
              :key="i"
              :command="schedule.command"
            >
              Create {{ schedule.label || "" }} Schedule</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </eden-page-header>
    <el-tabs v-model="tab" @tab-click="updateRouteQuery">
      <el-tab-pane
        v-for="(schedule, i) in schedules"
        :key="i"
        :label="schedule.label"
        :name="schedule.command"
      >
        <component :is="`schedule-${schedule.command}`" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ScheduleBeauty from "@/components/Logistics/Schedule/ScheduleBeauty";
import ScheduleCleaning from "@/components/Logistics/Schedule/ScheduleCleaning";
import ScheduleLaundryPickup from "@/components/Logistics/Schedule/ScheduleLaundryPickup";
import ScheduleLaundryDelivery from "@/components/Logistics/Schedule/ScheduleLaundryDelivery";
import ScheduleMeal from "@/components/Logistics/Schedule/ScheduleMeal";

export default {
  name: "Schedule",
  components: {
    ScheduleBeauty,
    ScheduleCleaning,
    ScheduleLaundryPickup,
    ScheduleLaundryDelivery,
    ScheduleMeal,
  },
  data() {
    return {
      tab: "meal",
    };
  },
  computed: {
    location() {
      return this.$store.getters.location;
    },
    services() {
      return this.$store.getters.services;
    },
    schedules() {
      let schedules = [];
      this.services.forEach((service) => {
        switch (service.slug) {
          case "beauty":
            schedules.push({
              label: "Beauty",
              command: "beauty",
            });
            break;
          case "cleaning":
            schedules.push({
              label: "Cleaning",
              command: "cleaning",
            });
            break;
          case "laundry":
            schedules.push({
              label: "Laundry Pickup",
              command: "laundry-pickup",
            });
            schedules.push({
              label: "Laundry Delivery",
              command: "laundry-delivery",
            });
            break;
          case "meal":
            schedules.push({
              label: "Food Delivery",
              command: "meal",
            });
            break;
          default:
            break;
        }
      });
      return schedules;
    },
  },
  created() {
    let { t } = this.$route.query;
    this.tab = t ? t : "meal";
  },
  methods: {
    updateRouteQuery(tab) {
      const { t } = this.$route.query;
      if (t === this.tab) {
        return false;
      }
      this.$router.push({
        name: "logistics.schedule.index",
        query: { t: tab.name },
      });
    },
    schedule(command) {
      this.$router.push({
        name: "logistics.schedule.create",
        params: {
          service: command,
        },
      });
    },
  },
};
</script>
