export const beautySchedule = () => {
  return [
    {
      order_id: "3798227",
      customer: "Damola Adegoke",
      customer_id: "12",
      gardener: "Ope Bello",
      customer_phone_number: "08123456789",
      type: "standard",
      quantity: 2,
      date: "2022-04-11",
      status: "arrived",
      locationarea: "Kilimani",
      customer_address: "Moon Valley Apartments",
      start_time: "8:45",
      completion_time: "11:45",
    },
    {
      order_id: "3798227",
      customer: "Damola Adegoke",
      customer_id: "12",
      gardener: "Ope Bello",
      customer_phone_number: "08123456789",
      type: "premium",
      quantity: 1,
      date: "2022-04-11",
      status: "arrived",
      locationarea: "Kilimani",
      customer_address: "Moon Valley Apartments",
      start_time: "8:45",
      completion_time: "11:45",
    },
  ];
};

export const cleaningSchedule = () => {
  return [
    {
      order_id: "3798227",
      customer: "Damola Adegoke",
      customer_id: "12",
      gardener: "Ope Bello",
      customer_phone_number: "08123456789",
      type: "deep-cleaning",
      quantity: 6,
      cleaners: ["Tola Bola", "Ajayi Thompson"],
      date: "2022-04-11",
      status: "arrived",
      locationarea: "Ikeja",
      customer_address: "9, 3rd Avenue, Nureni Yusuf Estate, Alakuko, Lagos",
      start_time: "8:45",
      completion_time: "11:45",
    },
    {
      order_id: "3798227",
      customer: "Damola Adegoke",
      customer_id: "12",
      gardener: "Ope Bello",
      customer_phone_number: "08123456789",
      type: "deep-cleaning",
      quantity: 8,
      cleaners: ["Tola Bola", "Ajayi Thompson"],
      date: "2022-04-11",
      status: "completed",
      locationarea: "Ikeja",
      customer_address: "9, 3rd Avenue, Nureni Yusuf Estate, Alakuko, Lagos",
      start_time: "8:45",
      completion_time: "11:45",
    },
  ];
};

export const laundryPickupSchedule = () => {
  return [
    {
      rider: {
        name: "Samuel Shina",
        phone_number: "08123456789",
        company: "Dellyman",
        locationarea: "Abule Egba",
        completed_pickups: 2,
        total_pickups: 4,
        date: "2022-04-11",
        start_trip: "8:45",
        end_trip: "11:32",
      },
      orders: [
        {
          order_id: "3798227",
          customer: "Damola Adegoke",
          customer_id: "12",
          gardener: "Ope Bello",
          customer_phone_number: "08123456789",
          type: "wash-and-iron",
          quantity: 2,
          date: "2022-04-11",
          status: "delivered",
          locationarea: "Ikeja",
          customer_address:
            "9, 3rd Avenue, Nureni Yusuf Estate, Alakuko, Lagos",
          time: null,
        },
      ],
    },
    {
      rider: {
        name: "Samuel Shina",
        phone_number: "08123456789",
        company: "Dellyman",
        locationarea: "Abule Egba",
        completed_pickups: 2,
        total_pickups: 4,
        date: "2022-04-11",
        start_trip: "8:45",
        end_trip: "11:32",
      },
      orders: [
        {
          order_id: "3798227",
          customer: "Damola Adegoke",
          customer_id: "12",
          gardener: "Ope Bello",
          customer_phone_number: "08123456789",
          type: "wash-and-iron",
          quantity: 2,
          date: "2022-04-11",
          status: "delivered",
          locationarea: "Ikeja",
          customer_address:
            "9, 3rd Avenue, Nureni Yusuf Estate, Alakuko, Lagos",
          time: null,
        },
      ],
    },
  ];
};

export const laundryDeliverySchedule = () => {
  return [
    {
      rider: {
        name: "Samuel Shina",
        phone_number: "08123456789",
        company: "Dellyman",
        locationarea: "Abule Egba",
        completed_deliveries: 2,
        total_deliveries: 4,
        date: "2022-04-11",
        start_trip: "8:45",
        end_trip: "11:32",
      },
      orders: [
        {
          order_id: "3798227",
          customer: "Damola Adegoke",
          customer_id: "12",
          gardener: "Ope Bello",
          customer_phone_number: "08123456789",
          type: "wash-and-iron",
          quantity: 2,
          date: "2022-04-11",
          status: "delivered",
          locationarea: "Ikeja",
          customer_address:
            "9, 3rd Avenue, Nureni Yusuf Estate, Alakuko, Lagos",
          time: null,
        },
      ],
    },
    {
      rider: {
        name: "Samuel Shina",
        phone_number: "08123456789",
        company: "Dellyman",
        locationarea: "Abule Egba",
        completed_deliveries: 2,
        total_deliveries: 4,
        date: "2022-04-11",
        start_trip: "8:45",
        end_trip: "11:32",
      },
      orders: [
        {
          order_id: "3798227",
          customer: "Damola Adegoke",
          customer_id: "12",
          gardener: "Ope Bello",
          customer_phone_number: "08123456789",
          type: "wash-and-iron",
          quantity: 2,
          date: "2022-04-11",
          status: "delivered",
          locationarea: "Ikeja",
          customer_address:
            "9, 3rd Avenue, Nureni Yusuf Estate, Alakuko, Lagos",
          time: null,
        },
      ],
    },
  ];
};

export const mealSchedule = () => {
  return [
    {
      rider: {
        name: "Samuel Shina",
        phone_number: "08123456789",
        company: "Dellyman",
        locationarea: "Abule Egba",
        completed_deliveries: 2,
        total_deliveries: 4,
        date: "2022-04-11",
        start_trip: "8:45",
        end_trip: "11:32",
      },
      orders: [
        {
          order_id: "3798227",
          customer: "Damola Adegoke",
          customer_id: "12",
          gardener: "Ope Bello",
          customer_phone_number: "08123456789",
          quantity: 2,
          date: "2022-04-11",
          status: "delivered",
          locationarea: "Ikeja",
          customer_address:
            "9, 3rd Avenue, Nureni Yusuf Estate, Alakuko, Lagos",
          time: null,
        },
      ],
    },
    {
      rider: {
        name: "Samuel Shina",
        phone_number: "08123456789",
        company: "Dellyman",
        locationarea: "Abule Egba",
        completed_deliveries: 2,
        total_deliveries: 4,
        date: "2022-04-11",
        start_trip: "8:45",
        end_trip: "11:32",
      },
      orders: [
        {
          order_id: "3798227",
          customer: "Damola Adegoke",
          customer_id: "12",
          gardener: "Ope Bello",
          customer_phone_number: "08123456789",
          quantity: 2,
          date: "2022-04-11",
          status: "delivered",
          locationarea: "Ikeja",
          customer_address:
            "9, 3rd Avenue, Nureni Yusuf Estate, Alakuko, Lagos",
          time: null,
        },
      ],
    },
  ];
};
